<template>
	<div>
		<b-overlay :show="xPhysicalIndexes == null" rounded="sm">

			<b-row>
				<b-col cols="3" v-if="xPhysical">
					<PhysicalIndex :dataset="xPhysical"></PhysicalIndex>
				</b-col>
			</b-row>

		</b-overlay>
	</div>
</template>

<script>

export default {
	components: {
		PhysicalIndex: require('@/components/v2/Player/comp-xPhysicalIndex.vue').default
	},
	props: [ 'player' ],
	data: function() {
		return {
			xPhysicalSelected: null,
			xPhysicalIndexes: null
		}
	},
	computed: {
		_: function() {
			return _;
		},
		xPhysical: function() {
			return this.xPhysicalIndexes[this.xPhysicalSelected];
		}
	},
	watch: {
		player: {
			immediate: true,
			handler: function() {
				this.fetchIndex();
			}
		}
	},
	methods: {
		fetchIndex: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skillcorner/xPhysical/' + this.player.persona.id + '/test').then(function(data) {
				this.xPhysicalIndexes = data.data;
				this.xPhysicalSelected = _.size(data.data) - 1;
			});
		}
	}
}

</script>