<template>
	<div>
		<b-row v-if="ruoli_perc && ruoli_perc[season_ruoli_sel]"><b-col cols="2" class="p-1 text-center" v-for="role in ruoli_perc[season_ruoli_sel]"><b-badge pill variant="warning" style="width:3rem;">{{ role.sigla }} <br/> {{ _.round(role.percentuale) }}%</b-badge></b-col></b-row>
		<b-form-select v-model="season_ruoli_sel" :options="season_ruoli_options" v-if="season_ruoli_options && season_ruoli_options.length" size="sm"></b-form-select>
	</div>
</template>

<script>

export default {
	props: [ 'player' ],
	data: function() {
		return {
			ruoli_perc: null,
			season_ruoli_options: null,
			season_ruoli_sel: null
		}
	},
	computed: {
		_: function() {
			return _;
		}
	},
	watch: {
		player: {
			immediate: true,
			handler: function() {
				this.fetchRuoli();
			}
		}
	},
	methods: {
		fetchRuoli: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/statsbomb/roles/percentage/' + this.player.persona.id).then(function(data) {
				this.ruoli_perc = data.data;
				this.season_ruoli_options = _.keys(this.ruoli_perc);
				if(this.season_ruoli_options.length > 0) {
					this.season_ruoli_sel = this.season_ruoli_options[0];
				}
			});
		},
	}
}

</script>