<template>
	<span>
		<b-button size="sm" @click.prevent="ux.show = true" block variant="outline-secondary" v-if="results && results.length">Valutazioni {{ results.length }}</b-button>

		<b-sidebar @hidden="ux.show = false" ref="mod" :visible="ux.show" width="60%" backdrop class="text-left">
			<div class="mx-2 mb-3">
				<b-row>
					<b-col>
						<b-list-group v-for="valutazione in results">
							<b-list-group-item style="cursor:pointer;" @click.prevent="currentValutazione = valutazione">
								<p class="lead mb-0"><span class="text-muted">Valutazione</span> {{ valutazione.glossario.nome }}</p>
								<b-row align-v="center">
									<b-col cols="6">{{ valutazione.persona.nomeCompleto }}</b-col>
									<b-col cols="4">{{valutazione.created_at | date}}</b-col>
									<b-col cols="2" class="text-right"><b-button size="sm" @click.stop="deleteValutazione(valutazione.id)"><i class="fa fa-trash"></i> </b-button></b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
					<b-col cols="9">
						<singola-valutazione v-if="currentValutazione" :valutazione="currentValutazione"></singola-valutazione>
						<b-alert v-else variant="secondary" show>Seleziona una valutazione dalla lista.</b-alert>
					</b-col>
				</b-row>
			</div>
		</b-sidebar>
	</span>
</template>

<script>

var Auth = require('../../../auth.js');
import moment from 'moment'

export default {
	filters: {
		date: function(i) {
			return moment(i).format('DD/MM/YYYY')
		}
	},
	components: {
		SingolaValutazione: require('@/components/layout/Valutazioni/SingolaValutazione.vue').default,
	},
	data: function() {
		return {
			currentValutazione: null,
			results: null,
			ux: {
				show: false
			}
		}
	},
	props: ['address', 'refresh'],
	watch: {
		address: {
			immediate: true,
			handler: function() {
				this.fetchAddress();
			}
		},
		refresh: function() {

		}
	},
	methods: {
		deleteValutazione: function(id) {
			if(confirm("Confermi di voler eliminare la valutazione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/fr-valutazione/delete/' + id).then(function(data) {
					if(this.currentValutazione && this.currentValutazione.id == id) {
						this.currentValutazione = null;
					}
					this.fetchAddress();
				});
			}
		},

		fetchAddress: function() {
			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/has', { address: this.address }).then(function(data) {
				this.results = data.data;
			});
		}
	}
}

</script>