<template>
	<div>
		<b-nav>
			<b-nav-item :to="{ name: 'PlayerPersonaDashboard' }" :style="isSelected('Profile') ? 'background-color: pink' : ''">Profilo</b-nav-item>
			<b-nav-item :to="{ name: 'PlayerPersonaEvaluations' }" :style="isSelected('Scouting') ? 'background-color: pink' : ''">Valutazioni <b-badge v-if="player.valutazioni > 0">{{player.valutazioni}}</b-badge></b-nav-item>
			<b-nav-item :to="{ name: 'PlayerPersonaNotes' }" :style="isSelected('Notes') ? 'background-color: pink' : ''">Note <b-badge v-if="player.note > 0">{{player.note}}</b-badge></b-nav-item>
			<!-- <b-nav-item :to="{ name: 'PlayerPersonaInjuriesDashboard' }" :style="isSelected('Injuries') ? 'background-color: pink' : ''">Injuries</b-nav-item> -->
		</b-nav>
	</div>
</template>

<script>

export default {
	props: [ 'player' ],
	methods: {
		isSelected: function(name) {
			return name === _.last(this.$route.matched).meta.v2.area;
		}
	}
}	

</script>