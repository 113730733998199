<template>
	<div>
		<b-overlay :show="ux.waiting">
			<b-card v-if="!ux.collapsed" :no-body="isWidget ? true : false" :style="(isWidget ? 'border:none;' : '') + (contentHeight && isFullscreen ? 'max-height:' + contentHeight + 'px;overflow-y:auto;overflow-x:hidden;' : '')">
				
				<template #header v-if="!isWidget">
					<b-row>
						<b-col>
							Tracking Analysis
						</b-col>
						<b-col v-if="collapsable" class="text-right">
							
							<span v-if="results && finalData.startingDataset.length > 0"><a href="javascript:;" @click.prevent="printSession">Stampa Sessione</a> • </span>
							<a href="javascript:;" @click.prevent="ux.collapsed = true">Chiudi</a>

						</b-col>
					</b-row>
				</template>

				<!--button @click.prevent="fetchResults">!</button-->
				
				<div v-if="results">
					
					<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
						<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
							<b-tr>
								<b-th width="16%"></b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Tempo</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Distanza</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D. Rel</div>
									<div class="text-center small text-muted" style="line-height: 1">metri/min</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>15km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>20km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">D>25km/h</div>
									<div class="text-center small text-muted" style="line-height: 1">metri</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Power Events</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">N ACC</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">N DEC</div>
									<div class="text-center small text-muted" style="line-height: 1">numero</div>
								</b-th>

								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">Vel Max</div>
									<div class="text-center small text-muted" style="line-height: 1">km/h</div>
								</b-th>
								<b-th width="6%">
									<div class="text-center" style="line-height: 1; text-transform: uppercase;">%ED</div>
									<div class="text-center small text-muted" style="line-height: 1">%</div>
								</b-th>
								<b-th width="12%" colspan="2"></b-th>
								
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="item in finalData.startingDataset">
									<b-td v-html="item.match"></b-td>
									<b-td class="text-center">{{ item.tracking.total_duration | formatTime }}</b-td>
									<b-td class="text-center">{{ item.tracking.total_distance }}</b-td>
									<b-td class="text-center">{{ item.tracking.relative_distance }}</b-td>
									<b-td class="text-center">{{ item.tracking.velocity__15 }}</b-td>
									<b-td class="text-center">{{ item.tracking.velocity__20 }}</b-td>
									<b-td class="text-center">{{ item.tracking.velocity__25 }}</b-td>
									<b-td class="text-center">{{ item.tracking.pow_ev }}</b-td>
									<b-td class="text-center">{{ item.tracking.num_acc }}</b-td>
									<b-td class="text-center">{{ item.tracking.num_dec }}</b-td>
									<b-td class="text-center">{{ item.tracking.max_vel }}</b-td>
									<b-td class="text-center">{{ item.tracking.edi }}</b-td>
									<b-td colspan="2"></b-td>
								
								</b-tr>
							</b-tbody>
							<b-tfoot>
								<tr>
									<td colspan="2"></td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.total_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.total_distance))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.total_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.total_distance) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.relative_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.relative_distance))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.relative_distance, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.relative_distance) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__15, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__15))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__15, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__15) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__20, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__20))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__20, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__20) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__25, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__25))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.velocity__25, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.velocity__25) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.pow_ev, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.pow_ev))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.pow_ev, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.pow_ev) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_acc, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_acc))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_acc, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_acc) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_dec, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_dec))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.num_dec, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.num_dec) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.max_vel, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.max_vel))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.max_vel, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.max_vel) }} %</td>
									<td class="text-center" :style="'background-color:' + getBgRatio(ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.edi, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.edi))">{{ ratio(finalData.startingDataset[finalData.startingDataset.length - 2].tracking.edi, finalData.startingDataset[finalData.startingDataset.length - 1].tracking.edi) }} %</td>
								</tr>
							</b-tfoot>
						</b-table-simple>

						<b-table-simple small striped hover responsive bordered :sticky-header="contentHeight && !isFullscreen ?  (contentHeight - 50) + 'px' : '70vh'">
							<b-thead style="position:sticky;top:0;z-index:3;background-color:#FFFFFF">
								<b-tr>
									<b-th width="16%"></b-th>
									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'total_duration' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('total_duration')">Tempo</a></div>
									</b-th>
									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'total_distance' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('total_distance')">Distanza</a></div>
										<div class="text-center small text-muted" style="line-height: 1">metri</div>
									</b-th>
									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'relative_distance' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('relative_distance')">D. Rel</a></div>
										<div class="text-center small text-muted" style="line-height: 1">metri/min</div>
									</b-th>

									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__15' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__15')">D>15km/h</a></div>
										<div class="text-center small text-muted" style="line-height: 1">metri</div>
									</b-th>
									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__20' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__20')">D>20km/h</a></div>
										<div class="text-center small text-muted" style="line-height: 1">metri</div>
									</b-th>
									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'velocity__25' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('velocity__25')">D>25km/h</a></div>
										<div class="text-center small text-muted" style="line-height: 1">metri</div>
									</b-th>

									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'pow_ev' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('pow_ev')">Power Events</a></div>
										<div class="text-center small text-muted" style="line-height: 1">numero</div>
									</b-th>

									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'num_acc' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('num_acc')">N ACC</a></div>
										<div class="text-center small text-muted" style="line-height: 1">numero</div>
									</b-th>
									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'num_dec' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('num_dec')">N DEC</a></div>
										<div class="text-center small text-muted" style="line-height: 1">numero</div>
									</b-th>

									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'max_vel' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('max_vel')">Vel Max</a></div>
										<div class="text-center small text-muted" style="line-height: 1">km/h</div>
									</b-th>

									<b-th width="6%">
										<div class="text-center" style="line-height: 1; text-transform: uppercase;"><a href="javascript:;" :style="ux.orderBy == 'edi' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('edi')">%ED</a></div>
										<div class="text-center small text-muted" style="line-height: 1">%</div>
									</b-th>
									<b-th class="text-center" width="6%"><a href="javascript:;" :style="ux.orderBy == 'rpe' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('rpe')">RPE</a></b-th>
									<b-th class="text-center" width="6%"><a href="javascript:;" :style="ux.orderBy == 'tl' ? 'text-decoration: underline;' : ''" @click.prevent="changeOrder('tl')">TL</a></b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="item in finalData.current" v-if="item.total_duration > 0" :variant="item.persona.team ? 'info' : ''">
									<b-td>{{ item.persona.cognome }} {{ item.persona.nome }}</b-td>
									<b-td class="text-center">{{ item.total_duration | formatTime }}</b-td>
									<b-td class="text-center" :variant="isBest(item.total_distance, 'total_distance') ? 'success' : (isWorst(item.total_distance, 'total_distance') ? 'danger' : '')">{{ item.total_distance }}</b-td>
									<b-td class="text-center" :variant="isBest(item.relative_distance, 'relative_distance') ? 'success' : (isWorst(item.relative_distance, 'relative_distance') ? 'danger' : '')">{{ item.relative_distance }}</b-td>
									<b-td class="text-center" :variant="isBest(item.velocity__15, 'velocity__15') ? 'success' : (isWorst(item.velocity__15, 'velocity__15') ? 'danger' : '')">{{ item.velocity__15 }}</b-td>
									<b-td class="text-center" :variant="isBest(item.velocity__20, 'velocity__20') ? 'success' : (isWorst(item.velocity__20, 'velocity__20') ? 'danger' : '')">{{ item.velocity__20 }}</b-td>
									<b-td class="text-center" :variant="isBest(item.velocity__25, 'velocity__25') ? 'success' : (isWorst(item.velocity__25, 'velocity__25') ? 'danger' : '')">{{ item.velocity__25 }}</b-td>
									<b-td class="text-center" :variant="isBest(item.pow_ev, 'pow_ev') ? 'success' : (isWorst(item.pow_ev, 'pow_ev') ? 'danger' : '')">{{ item.pow_ev }}</b-td>
									<b-td class="text-center" :variant="isBest(item.num_acc, 'num_acc') ? 'success' : (isWorst(item.num_acc, 'num_acc') ? 'danger' : '')">{{ item.num_acc }}</b-td>
									<b-td class="text-center" :variant="isBest(item.num_dec, 'num_dec') ? 'success' : (isWorst(item.num_dec, 'num_dec') ? 'danger' : '')">{{ item.num_dec }}</b-td>
									<b-td class="text-center" :variant="isBest(item.max_vel, 'max_vel') ? 'success' : (isWorst(item.max_vel, 'max_vel') ? 'danger' : '')">{{ item.max_vel }}</b-td>
									<b-td class="text-center" :variant="isBest(item.edi, 'edi') ? 'success' : (isWorst(item.edi, 'edi') ? 'danger' : '')">{{ item.edi }}</b-td>
									<b-td class="text-center" :variant="isBest(item.rpe, 'rpe') ? 'success_' : (isWorst(item.rpe, 'rpe') ? 'danger_' : '')">{{ item.rpe }}</b-td>
									<b-td class="text-center" :variant="isBest(item.tl, 'tl') ? 'success_' : (isWorst(item.tl, 'tl') ? 'danger_' : '')">{{ item.tl }}</b-td>
								</b-tr>
							</b-tbody>
							<b-tfoot>
							</b-tfoot>
						</b-table-simple>		

					</div>

				</b-card>
				<span v-else-if="collapsable">
					<b-button size="sm" variant="info" block @click.prevent="ux.collapsed = false">Apri Tracking Analysis</b-button>
				</span>
			</b-overlay>
		</div>
	</template>

	<script>


	var Auth = require('@/auth.js');
	import moment from 'moment';

	var fields = [
		'total_duration',
		'total_distance',
		'relative_distance',
		'velocity__15',
		'velocity__20',
		'velocity__25',
		'pow_ev',
		'num_acc',
		'num_dec',
		'max_vel',
		'edi',
		'rpe',
		'tl'
		];

	function calculateMean(dataset, isMatch) {

		if(_.size(dataset) > 10 && isMatch)
		var amounts = 10;//_.size(dataset);
	else
		var amounts = _.size(dataset);
	var out = {};

	_.each(fields, function(u) {

		var values = _.filter(_.map(dataset, u));
	//	out[u] = _.round(_.sum(values) / values.length, 2);
		var p = _.filter(_.map(dataset, u));

		if(u == 'max_vel' || u == 'edi') {
			out[u] = _.round(_.sumBy(dataset, u)/_.size(p), (u == 'max_vel' || u == 'edi' ? 1 : 0));
		} else
			out[u] = _.round(_.sumBy(dataset, u)/amounts, (u == 'max_vel' || u == 'edi' ? 1 : 0));
	});

	out.relative_distance = _.round(out.total_distance/(out.total_duration/60),0)

	return out;

}

function calculateMeans(dataset, isMatch) {

	var u = _.cloneDeep(dataset);

	var hasPortieri = _.map(_.pickBy(u.persone, function(c) {
		return _.get(c, 'last_carriera_sportiva.ruolo_ideale[0].sigla') === 'PO';
	}), 'id');

	console.log(hasPortieri);

	var tracking = _.filter(u.tracking, function(p) {
		return hasPortieri.indexOf(p.persona_id) === -1;
	});

	console.log(u.tracking, tracking);

	console.log('Dataset to: ', u);
	u.tracking = calculateMean(tracking, isMatch);
	return u;

}

export default {
	props: [ 'collapsable', 'sessione', 'isWidget', 'contentHeight', 'isFullscreen', 'refresh', 'configWidget'],
	filters: {
		formatTime: function(seconds) {
			return moment.utc(moment.duration(seconds,'seconds').as('milliseconds')).format('HH:mm:ss');
		}
	},
	computed: {
		isMatch: function() {
			return this.sessione && this.sessione.sessione_tipo_glo_id == 15;
		},
		bests: function() {

			var ruoli = _.map(this.finalData.current, 'persona.last_carriera_sportiva.ruolo_ideale[0].sigla');
			var realCurrent = _.filter(_.map(_.cloneDeep(this.finalData.current), function(e, b) {
				if(ruoli[b] === 'PO') return false;
				if(e.persona && e.persona.team !== undefined) return false;
				if(e.total_duration == 0 || e.total_duration == null) return false;
				return e;
			}));

			var a = {};
			_.each(fields, function(f) {
				a[f] = {
					worsts: _.chunk(_.orderBy(_.map(realCurrent, f)), 3)[0],
					bests: _.chunk(_.reverse(_.orderBy(_.map(realCurrent, f))), 3)[0],
				}
			}.bind(this));

			return a;

		},
		finalData: function() {

			var outPutTable = [];
			var outPutTableMeans = [];

			_.each(this.results.output_tracking, function(i) {
				i.persona = _.find(this.results.persone, { id: i.persona_id });

				if('PO' != _.first(_.map([i.persona], 'last_carriera_sportiva.ruolo_ideale[0].sigla')))
					outPutTableMeans.push(i);

				outPutTable.push(i);
			}.bind(this));

			var meanBase = calculateMean(outPutTableMeans, this.isMatch);

			meanBase.persona = { team: true, cognome: 'Media', nome: '' };
			outPutTable.push(meanBase);

			outPutTable = _.orderBy(outPutTable, [ this.ux.orderBy ], [ this.ux.orderDir ]);

			var otherBlocks = _.map(this.results.matches_prev, function(a) {
				console.log('Mean of: ', a);
				var c = _.cloneDeep(a);



				return calculateMeans(a, true);
			});

			if(this.sessione.sessione_tipo_glo_id == 15) {
				var currentMatch = {
					match: this.results.current_match ? this.results.current_match.match : 'Media Partita',
					tracking: meanBase
				};
				otherBlocks.push(currentMatch);

				var mediaTeam = {
					match: 'Media Squadra',
					tracking: this.results.team_average
				};
				otherBlocks.push(mediaTeam);

			} else {
				var currentMatch = {
					match: 'Media Squadra',
					tracking: meanBase
				};

				otherBlocks.push(currentMatch);

			}

			return { current: outPutTable, startingDataset: otherBlocks };

		}
	},
	methods: {
		printSession: function() {
			window.open(this.$store.state.apiEndPoint + '/tracking-tool/pdf-sessione/'+this.sessione.id+'/'+this.sessione.sessione_tipo_glo_id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");		
		},
		ratio: function(a, b) {
			if(this.isMatch)
				return _.round(100*a/b);
			else
				return _.round(100*b/a);
		},
		getBgRatio: function (value) {
			if(this.isMatch) {
				if(value <= 100) {
					return '#12a14b';
				} else if(value > 100 && value <= 110) {
					return '#ffff00';
				} else if(value > 110 && value <= 120) {
					return '#ff930e';
				} else if(value > 120) {
					return '#fb0102';
				}
			} else {
				if(value > 100) {
					return '#12a14b';
				} else if(value > 66 && value <= 100) {
					return '#ffff00';
				} else if(value > 33 && value <= 66) {
					return '#ff930e';
				} else if(value <= 33) {
					return '#fb0102';
				}
			}
		},
		changeOrder: function(key) {
			if(this.ux.orderBy === key) {
				if(this.ux.orderDir == 'asc')
					this.ux.orderDir = 'desc';
				else
					this.ux.orderDir = 'asc';
			} else {
				this.ux.orderBy = key;
				this.ux.orderDir = 'asc';
			}
		},
		isBest: function(value, k) {
			if(this.bests[k].bests.indexOf(value) !== -1)
				return true;
		},
		isWorst: function(value, k) {
			if(this.bests[k].worsts.indexOf(value) !== -1)
				return true;
		},
		fetchResults: function() {
			this.ux.waiting = true;
			this.$http.post(this.$store.state.apiEndPoint + '/tools/table-tracking/' + this.sessione.id).then(function(data) {
				this.ux.waiting = false;
				this.results = data.data;
			});
		},
	},
	watch: {
		'ux.collapsed': {
			handler: function() {
				this.fetchResults();
			}
		}
	},
	data: function() {
		return {
			results: false,
			ux: {
				orderBy: 'total_distance',
				orderDir: 'desc',
				waiting: false,
				collapsed: true
			}
		}
	}
}

</script>