<template>
	<div>

		<!-- <pre>{{ evaluation }}</pre> -->

		<b-form-group label="Valore 1">
			<b-input :readonly="read" type="number" v-model.number="evaluation.evaluation.gruppo_1.valore_1.value" />
			<b-textarea :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_1.note"></b-textarea>
		</b-form-group>


		<b-form-group label="Valore 2">
			<b-input :readonly="read" type="number" v-model.number="evaluation.evaluation.gruppo_1.valore_2.value" />
			<b-textarea :readonly="read" v-model="evaluation.evaluation.gruppo_1.valore_2.note"></b-textarea>
		</b-form-group>

		<!--
		<b-form-group label="Caratteristiche Fisiche">
			<b-textarea size="sm"></b-textarea>
		</b-form-group>

		<b-form-group label="Caratteristiche Tecniche">
			<b-textarea size="sm"></b-textarea>
		</b-form-group>

		<b-form-group label="Caratteristiche Tattiche">
			<b-textarea size="sm"></b-textarea>
		</b-form-group>

		<b-form-group label="Caratteristiche Comportamentali">
			<b-textarea size="sm"></b-textarea>
		</b-form-group>

		<b-form-group label="Giudizio Personale">
			<b-textarea size="sm"></b-textarea>
		</b-form-group>
		-->

	</div>
</template>

<script>
	
export default {
	props: [ 'evaluation', 'read' ],
	created: function() {

		if(this.evaluation.evaluation == null) {
			this.$set(this.evaluation, 'evaluation', {
				gruppo_1: {
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null }
				}
			});
		}

	}
}

</script>