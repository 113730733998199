<template>
	<div>
		<b-overlay :show="ux.loading">
			<b-row>
				<b-col>
					<b-list-group v-for="valutazione in results">
						<b-list-group-item style="cursor:pointer;" @click.prevent="currentValutazione = valutazione">
							<p class="lead mb-0">{{ valutazione.glossario.nome }}</p>
							<b-row align-v="center">
								<b-col cols="6">{{ valutazione.persona.nomeCompleto }}</b-col>
								<b-col cols="4">{{valutazione.created_at | date}}</b-col>
								<b-col cols="2" class="text-right"><b-button size="sm" @click.stop="deleteValutazione(valutazione.id)"><i class="fa fa-trash"></i> </b-button></b-col>
							</b-row>
						</b-list-group-item>
					</b-list-group>
				</b-col>
				<b-col cols="9">
					<singola-valutazione v-if="currentValutazione" :valutazione="currentValutazione"></singola-valutazione>
					<b-alert v-else variant="secondary" show>Seleziona una valutazione dalla lista.</b-alert>
				</b-col>
			</b-row>
		</b-overlay>
	</div>
</template>

<script>

var Auth = require('../../../auth.js');
import moment from 'moment'

export default {
	props: [ 'player' ],
	watch: {
		player: {
			immediate: true,
			handler: function() {
				this.fetchAddress();
			}
		}
	},
	filters: {
		date: function(i) {
			return moment(i).format('DD/MM/YYYY')
		}
	},
	components: {
		SingolaValutazione: require('@/components/layout/Valutazioni/SingolaValutazione.vue').default,
	},
	data: function() {
		return {
			currentValutazione: null,
			results: null,
			ux: {
				loading: false
			}
		}
	},
	methods: {
		deleteValutazione: function(id) {
			if(confirm("Confermi di voler eliminare la valutazione ?")) {
				this.$http.get(this.$store.state.apiEndPoint + '/fr-valutazione/delete/' + id).then(function(data) {
					if(this.currentValutazione && this.currentValutazione.id == id) {
						this.currentValutazione = null;
					}
					this.fetchAddress();
				});
			}
		},
		fetchAddress: function() {
			this.ux.loading = true;
			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/has', { address: { Persona: this.player.persona.id } }).then(function(data) {
				this.ux.loading = false;
				this.results = data.data;
			});
		}
	}
}

</script>