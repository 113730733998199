<template>
	<div>
		<NoteManager entity="Persona" @post="$emit('refresh')" :entityId="player.persona.id"></NoteManager>
	</div>
</template>

<script>

export default {
	components: {
		NoteManager: require('@/components/layout/notes/manager.vue').default,
	},
	props: [ 'player' ],
	data: function() {
		return {
			notes: null
		}
	},
	watch: {
		player: {
			immediate: true,
			handler: function() {
				// this.fetchNotes();
			}
		}
	},
	methods: {
		fetchNotes: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/note/giocatore/' + this.player.persona.id).then(function(data) {
				this.notes = data.data;
			});
		}
	}
}

</script>