<template>
	<div>
		<b-nav vertical>
			<b-nav-item :to="{ name: 'PlayerPersonaEvaluations' }" :style="isSelected('PlayerPersonaEvaluations') ? 'background-color: white; border-top-left-radius: 10px; border-bottom-left-radius: 10px;' : ''">Valutazioni</b-nav-item>
			<b-nav-item :to="{ name: 'PlayerPersonaNewEvaluation' }" :style="isSelected('PlayerPersonaNewEvaluation') ? 'background-color: white; border-top-left-radius: 10px; border-bottom-left-radius: 10px;' : ''">Nuova Valutazione</b-nav-item>
		</b-nav>
	</div>
</template>

<script>

export default {
	methods: {
		isSelected: function(name) {
			return name === this.$route.name;
		}
	}
}	

</script>