<template>
	<b-card>
		<template slot="header">xPhy</template>
		<E-Chart :option="chartOptions" style="width: 100%; height: 300px"></E-Chart>

		<div style="z-index: 999999999; position: relative;" class="text-center">
			<span class="lead">{{ dataset.ranking_position }}°</span> <span class="text-muted small">/ {{ dataset.ranking_count }}</span>
		</div>

		<!--
		<pre>{{ dataset.index.xPhy }}</pre>

		<pre>{{dataset}}</pre>-->

	</b-card>
</template>

<script>

import eChart from '@/components/layout/E-Chart/Base.vue';

export default {
	components: {
		'E-Chart': eChart
	},
	computed: {
		chartOptions: function() {

			return {
				series: [
					{
						type: 'gauge',
						progress: {
							show: true,
							width: 12
						},
						axisLine: {
							lineStyle: {
								width: 12
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							length: 15,
							lineStyle: {
								width: 1,
								color: '#999'
							}
						},
						axisLabel: {
							distance: 25,
							color: '#999',
							fontSize: 10
						},
						anchor: {
							show: true,
							showAbove: true,
							size: 10,
							itemStyle: {
								borderWidth: 20
							}
						},
						title: {
							show: false
						},
						detail: {
							valueAnimation: true,
							fontSize: 22,
							offsetCenter: [0, '70%']
						},
						data: [
							{
								value: this.dataset.index.xPhy
							}
						]
					}
				]
			}

		}
	},
	props: [ 'dataset' ]
}

</script>