<template>
	<div class="mt-2">
		<div class="text-sm">Modalità</div>
		<b-form-radio-group  v-model="evaluation.evaluation.gruppo_1.valore_1.value" size="sm">
			<b-form-radio :disabled="read" value="stadio" title="Live"><i class="fa fa-car"></i> Stadio</b-form-radio>
			<b-form-radio :disabled="read" value="video" title="Video"><i class="fa fa-video-camera"></i> Video</b-form-radio>
			<b-form-radio :disabled="read" value="actions_report" title="Actions report"><i class="fa fa-fast-forward"></i> Actions report</b-form-radio>
		</b-form-radio-group>

		<b-row>
			<b-col>
				<div class="text-sm">Meteo</div>
				<b-form-select :disabled="read" size="sm" v-model="evaluation.evaluation.gruppo_1.valore_2.value" :options="meteo" value-field="id" text-field="nome"></b-form-select>
				<!--b-select size="sm">
					<b-select-option>A</b-select-option>
				</b-select-->
			</b-col>
			<b-col>
				<div class="text-sm">Vento</div>
				<b-form-select :disabled="read" size="sm" v-model="evaluation.evaluation.gruppo_1.valore_3.value" :options="venti" value-field="id" text-field="nome"></b-form-select>
			</b-col>
			<b-col>
				<div class="text-sm">Tipologia Campo</div>
				<b-form-select :disabled="read" size="sm" v-model="evaluation.evaluation.gruppo_1.valore_4.value" :options="tipi_campo" value-field="id" text-field="nome"></b-form-select>
			</b-col>
			<b-col>
				<div class="text-sm">Condizione Campo</div>
				<b-form-select :disabled="read" size="sm" v-model="evaluation.evaluation.gruppo_1.valore_5.value" :options="condizioni_campo" value-field="id" text-field="nome"></b-form-select>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<div class="text-sm">Valore Avversario</div>
				<b-form-select :disabled="read" size="sm" id="voto_opponent" v-model="evaluation.evaluation.gruppo_1.valore_6.value">
					<option :value="null">-- Valore --</option>
					<option :value="1">+ forte</option>
					<option :value="2">equivalente</option>
					<option :value="3">+ bassa</option>
				</b-form-select>
			</b-col>
			<b-col cols="9">
				<div class="text-sm">Note sulla Partita</div>
				<b-textarea :disabled="read" size="sm" v-model="evaluation.evaluation.gruppo_1.valore_7.note"></b-textarea>
			</b-col>
		</b-row>

	</div>
</template>

<script>

export default {
	props: [ 'evaluation', 'read' ],
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	},
	created: function() {

		this.$http.get(this.$store.state.apiEndPoint + '/meteo').then((response) => {
			this.meteo = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/venti').then((response) => {	
			this.venti = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/tipi/campo').then((response) => {
			this.tipi_campo = response.data;    
		});

		this.$http.get(this.$store.state.apiEndPoint + '/condizioni/campo').then((response) => {
			this.condizioni_campo = response.data;    
		});

		if(this.evaluation.evaluation == null) {
			this.$set(this.evaluation, 'evaluation', {
				gruppo_1: {
					valore_1: { value: null, note: null },
					valore_2: { value: null, note: null },
					valore_3: { value: null, note: null },
					valore_4: { value: null, note: null },
					valore_5: { value: null, note: null },
					valore_6: { value: null, note: null },
					valore_7: { value: null, note: null }
				},
			});
		}
	},
	watch: { 
	},
	methods: {
	},
	data: function() {
		return {
			meteo: [],
			venti: [],
			tipi_campo: [],
			condizioni_campo: [],
		}
	}
}

</script>