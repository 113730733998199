<template>
	<div>Transfer Market, persona_id <u>{{ player.persona.id }}</u></div>
</template>

<script>

export default {
	props: [ 'player' ]
}

</script>