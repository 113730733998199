<template>
	<div style="background: white;">
		<headapp></headapp>
		<b-container fluid v-if="player">
			<b-row no-gutters>
				<b-col cols="12">
					<Intestazione-Giocatore :player="player"></Intestazione-Giocatore>
					<div style="background-color: rgba(0,0,0,.1)" v-if="meta.components.menuLevel0">
						<router-view :player="player" name="menuLevel0" @refresh="fetchPersona"></router-view>
					</div>
				</b-col>
				<b-col cols="2" v-if="!loading && meta.components.menuLevel1" style="background: rgba(0,0,0,.15);" class="py-3 pl-2">
					<router-view :player="player" name="menuLevel1" @refresh="fetchPersona"></router-view>
				</b-col>
				<b-col :cols="meta.components.menuLevel1 ? 10 : 12" class="p-3" v-if="!loading">
					<router-view name="main" :player="player" @refresh="fetchPersona"></router-view>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	
var Auth = require('@/auth.js');

export default {
	components: {
		IntestazioneGiocatore: require('@/components/v2/Player/comp-intestazione.vue').default,
		headapp: require('@/components/layout/headapp.vue').default,
		menusub: require('@/components/layout/menusub.vue').default,
		footerapp: require('@/components/layout/footerapp.vue').default,
	},
	data: function() {
		return {
			player: null,
		}
	},
	watch: {
		'$route.params.persona_id': {
			immediate: true,
			handler: function() {
				this.fetchPersona(this.$route.params.persona_id);
			}
		}
	},
	computed: {
		meta: function() {
			return _.last(this.$route.matched);
		}
	},
	methods: {
		fetchPersona: function(personaId) {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/anagrafica/pre-giocatore/' + this.$route.params.persona_id).then(function(data) {
				this.player = data.data;
			});
		}
	}
}

</script>