<template>
	<div>
		<h2>Injuries</h2>
		<b-row>
			<b-col>Interni</b-col>
			<b-col>Esterni</b-col>
		</b-row>
	</div>
</template>

<script>

export default {
	props: [ 'player' ]
}

</script>