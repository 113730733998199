<template>
	<div>
		<b-nav vertical>
			<b-nav-item :to="{ name: 'PlayerPersonaDashboard' }" :style="isSelected('PlayerPersonaDashboard') ? 'background-color: white; border-top-left-radius: 10px; border-bottom-left-radius: 10px;' : ''">Dashboard</b-nav-item>
		</b-nav>
	</div>
</template>

<script>

export default {
	methods: {
		isSelected: function(name) {
			return name === this.$route.name;
		}
	}
}	

</script>