<template>

	<b-form @submit.prevent="inviaValutazioni">

		<div v-if="valutazioneGiocatore.length > 0">
			<div v-for="a,k in valutazioneGiocatore" class="mb-3" style="background-color: rgba(0,0,0,.05); border-radius: 5px; padding: 0.5em; border: 1px solid rgba(0,0,0,.1)">
				<b-row class="d-flex align-items-center mb-2">
					<b-col>
						<p class="lead ml-1 mb-0">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(a)}}</span></p>
					</b-col>
					<b-col class="text-right"><a href="javascript:;" @click.prevent="removePlayer(k)">&times; Rimuovi</a></b-col>
				</b-row>
				<div :evaluation="a" :is="calculateTypePlayer(a)"></div>
			</div>
		</div>

		<hr v-if="valutazioneGiocatore.length > 0" />
		<div v-else class="py-1" style="width: 5px"></div>
		<div class="lead text-muted my-2 text-center" v-if="valutazioneGiocatore.length > 0">Inserisci un altra valutazione</div>

		<b-row class="align-items-center" :class="{ 'my-2': valutazioneGiocatore.length == 0 }">
			<b-col cols="3" class="mb-1" style="height: 100%" v-for="val in typeOfPlayerValutations">
				<b-card align="center" @click.prevent="addValutazioneGiocatore(val)" :style="(!isPlayerClick(val.component) ? 'cursor:pointer;height:100%;' : 'opacity: .5;') + 'line-height: 1; height: 72px;'">
					{{ val.name }}
				</b-card>
			</b-col>
		</b-row>

		<center class="my-3">
			<b-button type="submit" v-if="valutazioneGiocatore.length > 0 || valutazionePartita.length > 0">Salva Valutazion{{ valutazioneGiocatore.length == 1 ? 'e':'i' }}</b-button>
			<!--b-button @click="$emit('refresh', player.id)">Refresh</b-button-->
		</center>

	</b-form>

</template>

<script>

var Auth = require('../../../auth.js');

var ValutazioniPlayer = [
	{ entity: 'Persona', name: 'QUALITATIVE REPORT AM', component: 'QualitativeReportAm', role: null, exr: [ 'df', 'md', 'fw' ], valutazione_glo_id: 4 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT CB', component: 'QualitativeReportCb', role: null, exr: [ 'df', 'md', 'fw' ], valutazione_glo_id: 1 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT CF', component: 'QualitativeReportCf', role: null, exr: [ 'df', 'md', 'fw' ], valutazione_glo_id: 7 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT CM', component: 'QualitativeReportCm', role: null, exr: [ 'df', 'md', 'fw' ], valutazione_glo_id: 8 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT FB', component: 'QualitativeReportFb', role: null, exr: [ 'df', 'md', 'fw' ], valutazione_glo_id: 9 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT GK', component: 'QualitativeReportGk', role: null, exr: [ 'gk' ], valutazione_glo_id: 6 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT W', component: 'QualitativeReportW', role: null, exr: [ 'df', 'md', 'fw' ], valutazione_glo_id: 5 },

	{ entity: 'Persona', name: 'QUANTITATIVE REPORT', component: 'QuantitativeReport', role: null, valutazione_glo_id: 3 },
/*	{ name: 'Breve', component: 'Breve', role: null }, */
	{ entity: 'Match', name: 'Partita', component: 'Breve', role: null, valutazione_glo_id: 2 },
];

export default {
	props: [ 'player' ],
	components: {
		ValutazionePartitaBreve: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazionePartitaEstesa: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazioneGiocatoreBreve: require('@/components/layout/Valutazioni/ValutazioneGiocatoreBreve.vue').default,
		ValutazioneGiocatoreEstesa: require('@/components/layout/Valutazioni/ValutazioneGiocatoreEstesa.vue').default,
		ValutazioneGiocatoreQualitativeReportGk: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportGk.vue').default,
		ValutazioneGiocatoreQualitativeReportAm: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportAm.vue').default,
		ValutazioneGiocatoreQualitativeReportCb: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCb.vue').default,
		ValutazioneGiocatoreQualitativeReportCf: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCf.vue').default,
		ValutazioneGiocatoreQualitativeReportCm: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCm.vue').default,
		ValutazioneGiocatoreQualitativeReportFb: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportFb.vue').default,
		ValutazioneGiocatoreQualitativeReportW: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportW.vue').default,
		ValutazioneGiocatoreQuantitativeReport: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQuantitativeReport.vue').default,
	},
	methods: {
		generateName: function(name) {
			return _.startCase(name.kind);
		},
		isPlayerClick: function(k) {
			return _.find(this.valutazioneGiocatore, { kind: k });
		},
		isMatchClick: function(k) {
			return _.find(this.valutazionePartita, { kind: k });
		},
		removeMatch: function(k) {
			this.valutazionePartita.splice(k, 1);
		},
		removePlayer: function(k) {
			this.valutazioneGiocatore.splice(k, 1);
		},
		calculateTypePlayer: function(what) {
			return 'ValutazioneGiocatore' + what.kind;
		},
		calculateTypePartita: function(what) {
			return 'ValutazionePartita' + what.kind;
		},
		addValutazioneGiocatore: function(tipo) {
			if(!this.isPlayerClick(tipo.component))
				this.valutazioneGiocatore.push({ kind: tipo.component, evaluation: null, valutazione_glo_id: tipo.valutazione_glo_id });
		},
		addValutazionePartita: function(tipo) {
			if(!this.isMatchClick(tipo))
				this.valutazionePartita.push({ kind: tipo.component, evaluation: null, valutazione_glo_id: tipo.valutazione_glo_id});
		},
		inviaValutazioni: function() {

			this.ux.load = true

			var relazioni = [
				{ entita: this.player.persona.id ? 'Persona' : 'ws_player', entita_id: this.player.persona.id ? this.player.persona_id : this.player.id },
			];

			this.$http.post(this.$store.state.apiEndPoint + '/fr-valutazioni/create', { persona: this.valutazioneGiocatore, relazioni }).then(function(data) {
				this.$emit('refresh', this.player.id);
				this.valutazioneGiocatore = []
				this.valutazionePartita = []
				this.ux.load = false
				this.ux.show = false
			});

		}
	},
	computed: {
		searchObject: function() {
			var u = {};
			u.player = this.player.id;
			if(this.match)
				u.match = this.match.partita.wyId;
			
			return u;
		},
		typeOfPlayerValutations: function() {
			console.log(this.player);
			return _.filter(ValutazioniPlayer, function(i) {
				return (i.role === null || i.role.indexOf(this.player.role) !== -1) && i.entity == 'Persona';
			}.bind(this));

		},
		typeOfMatchValutations: function() {
			return _.filter(ValutazioniPlayer, function(i) {
				return i.entity == 'Match'
			}.bind(this));
		}
	},
	data: function() {
		return {
			ux: {
				show: false,
				load: false,
			},
			valutazioneGiocatore: [],
			valutazionePartita: []
		}
	},
	mounted: function() {
		// this.$refs.mod.show();
	}
}
</script>