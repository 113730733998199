<template>
	<div>
		<p class="lead ml-1 mb-0">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(valutazione)}}</span></p>
		<div :evaluation="valutazione" :is="valutazione.valutazione_glo_id == 2 ? calculateTypePartita(valutazione) : calculateTypePlayer(valutazione)" :read="true"></div>
	</div>
</template>

<script>

var Auth = require('../../../auth.js');

var ValutazioniPlayer = [
	{ entity: 'Persona', name: 'QUALITATIVE REPORT AM', component: 'QualitativeReportAm', role: [ 'df', 'md', 'fw' ], valutazione_glo_id: 4 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT CB', component: 'QualitativeReportCb', role: [ 'df', 'md', 'fw' ], valutazione_glo_id: 1 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT CF', component: 'QualitativeReportCf', role: [ 'df', 'md', 'fw' ], valutazione_glo_id: 7 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT CM', component: 'QualitativeReportCm', role: [ 'df', 'md', 'fw' ], valutazione_glo_id: 8 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT FB', component: 'QualitativeReportFb', role: [ 'df', 'md', 'fw' ], valutazione_glo_id: 9 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT GK', component: 'QualitativeReportGk', role: [ 'gk' ], valutazione_glo_id: 6 },
	{ entity: 'Persona', name: 'QUALITATIVE REPORT W', component: 'QualitativeReportW', role: [ 'df', 'md', 'fw' ], valutazione_glo_id: 5 },

	{ entity: 'Persona', name: 'QUANTITATIVE REPORT', component: 'QuantitativeReport', role: null, valutazione_glo_id: 3 },
	{ name: 'Partita', component: 'Breve', role: null },
	{ name: 'Estesa', component: 'Estesa', role: null },
];

export default {
	props: [ 'valutazione' ],
	components: {
		ValutazionePartitaBreve: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazionePartitaEstesa: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazioneGiocatoreBreve: require('@/components/layout/Valutazioni/ValutazioneGiocatoreBreve.vue').default,
		ValutazioneGiocatoreEstesa: require('@/components/layout/Valutazioni/ValutazioneGiocatoreEstesa.vue').default,
		ValutazioneGiocatoreQualitativeReportGk: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportGk.vue').default,
		ValutazioneGiocatoreQualitativeReportAm: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportAm.vue').default,
		ValutazioneGiocatoreQualitativeReportCb: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCb.vue').default,
		ValutazioneGiocatoreQualitativeReportCf: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCf.vue').default,
		ValutazioneGiocatoreQualitativeReportCm: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCm.vue').default,
		ValutazioneGiocatoreQualitativeReportFb: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportFb.vue').default,
		ValutazioneGiocatoreQualitativeReportW: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportW.vue').default,
		ValutazioneGiocatoreQuantitativeReport: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQuantitativeReport.vue').default,
	},
	methods: {
		generateName: function(name) {
			return this.valutazione.glossario.nome;
		},
		isPlayerClick: function(k) {
			return _.find(this.valutazioneGiocatore, { kind: k });
		},
		isMatchClick: function(k) {
			return _.find(this.valutazionePartita, { kind: k });
		},
		removeMatch: function(k) {
			this.valutazionePartita.splice(k, 1);
		},
		removePlayer: function(k) {
			this.valutazioneGiocatore.splice(k, 1);
		},
		calculateTypePlayer: function(what) {
			return 'ValutazioneGiocatore' + this.valutazione.glossario.component;
		},
		calculateTypePartita: function(what) {
			return 'ValutazionePartita' + this.valutazione.glossario.component;
		}
	},
	data: function() {
		return {
		}
	},
	mounted: function() {
		// this.$refs.mod.show();
	}
}

</script>