<template>
	<div>
		<headapp></headapp>
		<b-container fluid>
			<b-card v-if="player">
				<b-row>
					<b-col cols="5">
						<div class="media">
							<b-img :src="player.avatar_url" :height="120"/>
							<div class="media-body align-self-center ml-3">
								<h3 class="text-primary mb-0"><strong>{{player.nome}} {{player.cognome}}</strong></h3>
								<small class="text-muted">{{ player.last_carriera_sportiva && player.last_carriera_sportiva.ruolo_ideale.length > 0 ? player.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}</small>
							</div>
						</div>
					</b-col>
					<b-col cols="5">
					</b-col>
					<b-col cols="2" class="text-center">
						<b-row v-if="ruoli_perc && ruoli_perc[season_ruoli_sel]"><b-col cols="4" class="p-2" v-for="role in ruoli_perc[season_ruoli_sel]"><b-badge pill variant="warning" style="width:3rem;">{{ role.sigla }} <br/> {{ _.round(role.percentuale) }}%</b-badge></b-col></b-row>
						<b-form-select v-model="season_ruoli_sel" :options="season_ruoli_options" v-if="season_ruoli_options && season_ruoli_options.length" size="sm"></b-form-select>
					</b-col>
				</b-row>
			</b-card>
			<div v-if="indexes">
				<b-card v-if="_.isEmpty(indexes)">
					<div>Non sono stati trovati dati fisici sul giocatore.</div>
				</b-card>
				<b-card v-for="items, key in indexes" :title="key">
					<b-row>
						<b-col v-for="stats, role in items" lg="6" md="12" class="mb-2">
							<b-card :title="_.replace(role, /\_/g, ' ')" class="h-100">
								<b-table-simple small>
									<b-thead>
										<b-tr>
											<b-th>Category</b-th>
											<b-th>Statistic</b-th>
											<b-th class="text-center">Bracket</b-th>
											<b-th class="text-center">Value</b-th>
											<b-th class="text-center">Points</b-th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="stat, kpi, idx in stats" v-if="typeof stat === 'object'">
											<b-td>{{ getLabelCategory(_.values(_.cloneDeep(stats)), idx) }}</b-td>
											<b-td v-b-popover.hover.top="{ html: true, content: legendHtml(stat) }">{{ stat.label }}</b-td>
											<b-td class="text-center">{{ getBracket(stat.range, stat.rangeIndex) }}</b-td>
											<b-td class="text-center">{{ stat.value }}</b-td>
											<b-td class="text-center">{{ stat.points }}/{{ stat.maxPoints }}</b-td>
										</b-tr>
									</b-tbody>
									<b-tfoot>
											<b-tr>
												<b-th colspan="4" class="text-right">Total of points</b-th>
												<b-th class="text-center">{{ stats.totalPoint }}</b-th>
											</b-tr>
											<b-tr>
												<b-th colspan="4" class="text-right">Total maximum</b-th>
												<b-th class="text-center">{{ stats.totalMaximum }}</b-th>
											</b-tr>
											<b-tr>
												<b-th colspan="4" class="text-right">Calculation of the index</b-th>
												<b-th class="text-center">({{ stats.totalPoint }} / {{ stats.totalMaximum }}) x 100</b-th>
											</b-tr>
											<b-tr>
												<b-th colspan="4" class="text-right">xPhy</b-th>
												<b-th class="text-center">{{ stats.xPhy }}</b-th>
											</b-tr>
									</b-tfoot>
								</b-table-simple>
							</b-card>
							<!--pre>{{ stats }}</pre-->
						</b-col>
					</b-row>
				</b-card>
			</div>
		</b-container>
		<footerapp></footerapp>
	</div>
</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			player: null,
			indexes: null,	
			ruoli_perc: null,
			season_ruoli_sel: null,
			season_ruoli_options: null,
		}
	},
	components: {
	},
	created: function() {
		this.fetchGiocatore();
		this.fetchIndex();
		this.fetchRuoli();
	},
	methods: {
		fetchGiocatore: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/anagrafica/giocatore/' + this.$route.params.id).then(function(data) {
				this.player = data.data;
			});
		},
		fetchRuoli: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/statsbomb/roles/percentage/' + this.$route.params.id).then(function(data) {
				this.ruoli_perc = data.data;
				this.season_ruoli_options = _.keys(this.ruoli_perc);
				if(this.season_ruoli_options.length > 0) {
					this.season_ruoli_sel = this.season_ruoli_options[0];
				}
			});
		},
		fetchIndex: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/skillcorner/xPhysical/' + this.$route.params.id).then(function(data) {
				this.indexes = data.data;
			});
		},
		getBracket: function(range, index) {
			var bracket = '-';
			if(index != -1) {
				var interval = range[index];
				if(interval.min && interval.max) {
					bracket = 'beetween ' + interval.min + ' and ' + interval.max;
				} else if(interval.min && !interval.max) {
					bracket = '> ' + interval.min;
				} else if(!interval.min && interval.max) {
					bracket = '< ' + interval.max;
				}
			}
			return bracket;
		},
		getLabelCategory: function(array_values, idx) {
			var cat = array_values[idx].category;
			if(idx > 0) {
				var prevCat = array_values[idx - 1].category;
				if(cat == prevCat) {
					cat = '';
				}
			}
			return cat;
		},
		legendHtml: function(stat) {
			var html = [];
			html.push('<div><strong>' + stat.label + '</strong></div>');
			_.forEach(stat.range, function(item) {
				var label = '';
				if(item.min && item.max) {
					label = 'beetween ' + item.min + ' and ' + item.max;
				} else if(item.min && !item.max) {
					label = '> ' + item.min;
				} else if(!item.min && item.max) {
					label = '< ' + item.max;
				}
				label += ' : +' + item.points;
				html.push('<div>' + label + '</div>');
			});

			return html.join("\n");
		},
	},
	computed: {
	},
}

</script>