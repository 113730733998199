<template>
	<div>
		<div class="d-flex">
			<div class="lead mr-1">{{player.persona.nome}}</div>
			<div class="lead font-weight-bold">{{player.persona.cognome}}</div>
		</div>
		<b-row class="d-flex align-items-end py-2">
			<b-col cols="1">
				<img :src="player.persona.avatar_url" style="width: 100%; border: 1px solid rgba(0,0,0,0.2); border-radius: 5px;" />
			</b-col>
			<b-col>
				<div class="d-flex align-items-center mt-1">
					<img style="width: 20px; height: 20px" :src="player.persona.last_carriera_sportiva.squadra.logo_url" />
					<span class="small">{{player.persona.last_carriera_sportiva.squadra.officialName}}</span>
				</div>
				<div style="font-size: 12px">{{ player.persona.nascita_data | dob }}, {{ player.persona.nascita_paese.paese }}</div>
				<b-row style="line-height: 1; font-size: 13px" class="my-1">
					<b-col>
						<div style="text-transform: uppercase; font-size: 10px; letter-spacing: 1px;" class="muted">Altezza</div>
						<div>{{ player.persona.info_fisica.altezza }} <span class="muted small">cm</span></div>
					</b-col>
					<b-col>
						<div style="text-transform: uppercase; font-size: 10px; letter-spacing: 1px;" class="muted">Piede</div>
						<div>{{ player.persona.giocatore.piede == 0 ? 'Destro' : 'Sinistro' }}</div>
					</b-col>
				</b-row>
			</b-col>
			<b-col cols="7">
				<b-row>
					<b-col v-for="comp in $route.meta.v2.headComponents"><div v-if="comp !== null" :is="comp" :player="player"></div></b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import moment from 'moment'

export default {
	props: [ 'player' ],
	components: {
		TransferMarket: require('@/components/v2/Player/comp-TransferMarket.vue').default,
		ScoutFieldPosition: require('@/components/v2/Player/comp-ScoutFieldPosition.vue').default,
	},
	filters: {
		dob: function(a) {
			return moment(a).format('DD/MM/YYYY');
		}
	}
}	

</script>