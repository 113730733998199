export const ROUTES_V2_PLAYER = [
    {
        path: '/v2/player/:persona_id',
        name: 'PlayerPersona',
        component: require('@/components/v2/Player/container.vue').default,
        meta: {
            module: 'scout',
            label: 'Continenti',
            requiresAuth: true,
            showInMenu: false,
            parent: '',
            permesso: 'scout',
        },
        children: [
	        {
	        	path: '',
	        	name: 'PlayerPersonaDashboard',
	        	components: {
	        		main: require('@/components/v2/Player/page-dashboard.vue').default,
	        		menuLevel0: require('@/components/v2/Player/menu-0.vue').default,
	        		menuLevel1: require('@/components/v2/Player/menu-profile.vue').default,
	        	},
	        	meta: {
	        		v2: {
	        			headComponents: [ null, 'ScoutFieldPosition' ],
	        			area: 'Profile'
	        		}
	        	}
	        },
	        {
	        	path: 'injuries',
	        	name: 'PlayerPersonaInjuriesDashboard',
	        	components: {
	        		main: require('@/components/v2/Player/page-injuries.vue').default,
	        		menuLevel0: require('@/components/v2/Player/menu-0.vue').default,
	        		menuLevel1: null,
	        	},
	        	meta: {
	        		v2: {
	        			area: 'Injuries',
	        		}
	        	}
	        },
	        {
	        	path: 'notes',
	        	name: 'PlayerPersonaNotes',
	        	components: {
	        		main: require('@/components/v2/Player/page-notes.vue').default,
	        		menuLevel0: require('@/components/v2/Player/menu-0.vue').default,
	        		menuLevel1: null,//require('@/components/v2/Player/menu-evaluations.vue').default,
	        	},
	        	meta: {
	        		v2: {
	        			headComponents: [ null, 'ScoutFieldPosition' ],
	        			area: 'Notes',
	        		}
	        	}
	        },
	        {
	        	path: 'evaluations',
	        	name: 'PlayerPersonaEvaluations',
	        	components: {
	        		main: require('@/components/v2/Player/page-valutazioni.vue').default,
	        		menuLevel0: require('@/components/v2/Player/menu-0.vue').default,
	        		menuLevel1: require('@/components/v2/Player/menu-evaluations.vue').default,
	        	},
	        	meta: {
	        		v2: {
	        			headComponents: [ null, 'ScoutFieldPosition' ],
	        			area: 'Scouting',
	        		}
	        	}
	        },
	        {
	        	path: 'new-evaluation',
	        	name: 'PlayerPersonaNewEvaluation',
	        	components: {
	        		main: require('@/components/v2/Player/page-new_valutazione.vue').default,
	        		menuLevel0: require('@/components/v2/Player/menu-0.vue').default,
	        		menuLevel1: require('@/components/v2/Player/menu-evaluations.vue').default,
	        	},
	        	meta: {
	        		v2: {
	        			headComponents: [ null, 'ScoutFieldPosition' ],
	        			area: 'Scouting',
	        		}
	        	}
	        }
		]
	}
];
